import UserTypeMobile from '@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile';
import UserNavMobile from '@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile';
import Button from '@/components/Common/Buttons/Button/Button';
import Footer from "@/components/Footer/Footer";

export default {
    name: 'ProfileContainer',
    components: {
        UserTypeMobile,
        UserNavMobile,
        Button,
        Footer
    },
    data() {
        return {
            sidebar: null,
            isSidebarStatic: false,
            menuHeight: 0,
            sidebarOffsetTop: 0
        }
    },
    async mounted() {

        if (this.$store.state.user.authenticated === false) {
            await this.$store.dispatch('user/loadAuthenticatedUser');
        }

        if (this.$store.state.user.authenticated === false) {
            this.$router.push({'name': 'Index'});
            return;
        }

        this.sidebar = this.$refs.sidebar;
        this.bottomLine = this.$refs.bottomLine;
        this.menuHeight = this.sidebar.clientHeight;

        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollOffset = scrollTop + this.menuHeight + 120;
            const bottomOffset = this.bottomLine.offsetTop;

            this.isSidebarStatic = scrollOffset >= bottomOffset;
        },
    },
}

<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div id="main-container" v-if="this.$store.state.user.authenticated">
      <div class="container">
        <div class="d-flex">
          <div class="d-none d-md-block profile-sidebar">
            <div v-bind:class="{ 'admin-navigation': true, 'admin-navigation-bottom': isSidebarStatic }" ref="sidebar">
              <UserTypeMobile />
              <div class="mt-2 mb-2" v-if="this.$store.state.user.info.type === 'vendor'">
                <router-link :to="{ name: 'AdminAddProduct' }">
                  <Button :theme="'solid-orange'"
                          :size="'small'"
                          :text="'Добави продукт'"
                          :svg="'plus-white'"
                          @click="$router.push({name: 'AdminAddProduct'})"
                  />
                </router-link>
              </div>
              <div class="mt-2 mb-2" v-else>
                <router-link :to="{ name: 'AddProperty' }">
                  <Button
                      @click="$router.push({name: 'AddProperty'})"
                      :theme="'solid-orange'"
                      :size="'small'"
                      :text="'Добави имот'"
                      :svg="'plus-white'"
                  />
                </router-link>
              </div>
              <UserNavMobile :usertype="'regular'" />
            </div>
          </div>
          <div class="wrapper">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <div ref="bottomLine"></div>
    <Footer/>
  </div>
</template>

